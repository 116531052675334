<template>
  <el-dialog
    v-model="dialogVisible"
    :title="dialogVisibleTitle"
    :close-on-click-modal="false"
    width="600px"
  >
    <div class="confirmbox">
      <el-form
        ref="ruleFormRef"
        label-width="140px"
        :rules="rules"
        :model="state.ruleFormData"
      >
        <div class="formbox formbox1">
          <el-form-item
            label="使用者:"
            prop="userIds"
            v-if="dialogVisibleTitle == '新增授权'"
          >
            <el-select
              placeholder="请选择"
              v-model="state.ruleFormData.userIds"
              multiple
            >
              <el-option
                v-for="(el, ind) in state.personList"
                :key="ind"
                :value="el.userId"
                :label="el.chnname"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="开始时间:"  prop="startDate">
            <el-date-picker
              v-model="state.ruleFormData.startDate"
              type="datetime"
              value-format="YYYY-MM-DD HH:mm:ss"
              format="YYYY/MM/DD HH:mm:ss"
              placeholder="请选择开始时间"
            />
          </el-form-item>
          <el-form-item label="结束时间:" prop="endDate">
            <el-date-picker
              v-model="state.ruleFormData.endDate"
              type="datetime"
              value-format="YYYY-MM-DD HH:mm:ss"
              format="YYYY/MM/DD HH:mm:ss"
              placeholder="请选择结束时间"
            />
          </el-form-item>
          <el-form-item label="使用次数:"  prop="count">
            <el-input
 v-model="state.ruleFormData.count"
 type="number"
  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))">
</el-input>

            <!-- <el-input-number
              placeholder="请输入使用次数"
              min="0"
              v-model="state.ruleFormData.count"
            ></el-input-number> -->
          </el-form-item>
        </div>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton
          class="searcML"
          width="120"
          height="40"
          title="确定"
          CSStype="2"
          @buttonclick="submit()"
          v-model:loading="dialogloading"
        ></oabutton>
        <oabutton
          class="searcML"
          width="120"
          height="40"
          title="取消"
          CSStype="3"
          @buttonclick="dialogVisible = false"
        >
        </oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  reactive,
  ref,
  unref,
  nextTick,
  defineEmits,
  onMounted,
  defineProps,
  watch,
} from "vue";
import qs from "qs";
import { fa } from "element-plus/es/locale";
import { objdata_ret, objdata_set } from "@/utils/server/format";
import { httpToken } from "@/utils/request";

const dialogloading = ref(false); // 弹框按钮loading 显示
const dialogVisible = ref(false); //弹框显示
const dialogVisibleTitle = ref("新增授权");
const emit = defineEmits(["Emit",'updateList']);
const id = ref(0); //操作的id
const ruleFormRef = ref(); //表单
// const validatePass = (rule, value, callback) => {
//   if (value === "") {
//     callback(new Error("请选择结束时间"));
//   } else {
//     if (state.ruleFormData.startDate === "") {
//       callback(new Error("请先选择开始时间"));
//     } else if (state.ruleFormData.startDate >= state.ruleFormData.endDate) {
//       callback(new Error("结束时间请大于开始时间"));
//     } else {
//       callback();
//     }
//   }
// };
const rules = reactive({
  userIds: [{ required: true, message: "请选择使用者", trigger: "blur" }],
  // startDate: [{ required: true, message: "请选择开始时间", trigger: "blur" }],
  // endDate: [{ validator: validatePass, trigger: "blur" }],
  // count: [{ required: true, message: "请输入次数", trigger: "blur" }],
}); //表单规则
const props = defineProps({
  sealId: {
    type: String,
    default: "0",
  }, //传入的印章详情
});
const state = reactive({
  Notice: [],
  personList: [], //下拉框人员
  ruleFormData: {
    startDate: "",
    endDate: "",
    count: "",
    userIds: [],
  },
  id: "",
});

const getPerson = () => {
  httpToken({
    method: "post",
    url: "/admin/sys-user/getAllSealUser",
  })
    .then((res) => {
      // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
      if (res.code == 200) {
        state.personList = res.data;
      }
    })
    .catch(() => {
      loading.value = false;
    });
};
//新增印章
const addseal = () => {
  let data = {
    sealId: props.sealId,
    startDate: state.ruleFormData.startDate,
    endDate: state.ruleFormData.endDate,
    count: state.ruleFormData.count,
    userIds: state.ruleFormData.userIds,
  };
  console.log(data, "data");
  httpToken({
    method: "post",
    url: "seal/seal-admin/accredit",
    data: data,
  }).then((res) => {
      if (res.code == 200) {
        
        dialogloading.value = false;
        dialogVisible.value = false;
            emit('updateList')
      }
    }).catch(() => {

           dialogloading.value = false;
    });
};

const editseal = () => {
  let data = {
    sealId: props.sealId,
    startDate: state.ruleFormData.startDate,
    endDate: state.ruleFormData.endDate,
    count: state.ruleFormData.count,
    id: state.id,
  };
  httpToken({
    method: "post",
    url: "seal/seal-admin/edit",
    data: data,
  })
    .then((res) => {
      if (res.code == 200) {
       
        dialogloading.value = false;
        dialogVisible.value = false;
             emit('updateList')
      }
    })
    .catch(() => {
           dialogloading.value = false;
    });
};
  let fromDataReset=(()=>{
    objdata_ret(state.ruleFormData)
  })
  let add=(()=>{
    fromDataReset()
    dialogVisibleTitle.value = "新增授权"
    dialogVisible.value=true
    nextTick(()=>{
      unref(ruleFormRef).clearValidate()//清除表单验证结果
    })
  })
onMounted(() => {
  getPerson();
});

// 数据提交
const submit = async () => {
  dialogloading.value = true;
  const form = unref(ruleFormRef);
  if (!form) {
    dialogloading.value = false;
    return;
  }
  await form.validate((valid) => {
    if (valid) {
      if (dialogVisibleTitle.value === "新增授权") {
        addseal();
      } else if (dialogVisibleTitle.value === "修改授权") {
        editseal();
      }
    } else {
      dialogloading.value = false;
    }

  });
};
// 表格选中事件
defineExpose({
  dialogVisible,
  dialogVisibleTitle,
  state,
  add
});
</script>
<style lang="scss" scoped >
.confirmbox {
  ::v-deep .el-table .el-table__cell {
    height: 50px;
  }
}
</style>